import classes from './ConverterForm.module.scss';
import { useEffect, useRef, useState } from 'react';
import converterOptions from '../../utils/converterOptionsFunc';
import currencyOptions from '../../utils/currencyOptions';
import converter from '../../utils/converterFunc';

interface IConverterForm {
  converterType: string;
  currenciesList: {[key: string]: string}
}

export function ConverterForm({ converterType, currenciesList }: IConverterForm): JSX.Element {
  const [inputUnit, setInputUnit] = useState<string>('m');
  const [outputUnit, setOutputUnit] = useState<string>('km');
  const [inputValue, setInputValue] = useState<number | string>('');
  const [outputValue, setOutputValue] = useState<string>('');
  const prevState = useRef(converterType);

  useEffect(() => {
    if (inputValue && prevState.current === converterType) {
      setOutputValue(String(converter({ inputValue, inputUnit, outputUnit, converterType })));
    } else {
      setOutputValue('');
    }
  }, [inputValue, inputUnit, outputUnit, converterType]);

  useEffect(() => {
    if (converterType === 'length') {
      setInputUnit('m');
      setOutputUnit('km');
    } else if (converterType === 'weight') {
      setInputUnit('g');
      setOutputUnit('kg');
    } else if (converterType === 'speed') {
      setInputUnit('mps');
      setOutputUnit('kph');
    } else if (converterType === 'temperature') {
      setInputUnit('cel');
      setOutputUnit('fah');
    }
    else if (converterType === 'currency') {
      setInputUnit('USD');
      setOutputUnit('EUR');
    }
    prevState.current = converterType;
  }, [converterType]);

  return (
    <div className={classes.wrap}>
      <form className={classes.form}>
        <div className={classes.group}>
          <label>
            <input
              placeholder="input"
              className={classes.input}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              type="number"
            />
          </label>
          <select
            className={classes.select}
            value={inputUnit}
            onChange={(e) => {
              setInputUnit(e.target.value);
            }}
          >
            {converterType === 'currency' ? currencyOptions(currenciesList) : converterOptions(converterType)}
          </select>
        </div>
        <div className={classes.symbol}>➡</div>
        <div className={classes.group}>
          <label>
            <input placeholder="result" className={classes.input} value={outputValue} readOnly />
          </label>
          <select
            className={classes.select}
            value={outputUnit}
            onChange={(e) => {
              setOutputUnit(e.target.value);
            }}
          >
            {converterType === 'currency' ? currencyOptions(currenciesList) : converterOptions(converterType)}
          </select>
        </div>
      </form>
    </div>
  );
}
