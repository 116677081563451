import classes from './Header.module.scss';

interface Props {
  converterType: string;
  setConverterType: React.Dispatch<React.SetStateAction<string>>;
}

const headerItems: Array<string> = ['Length', 'Weight', 'Speed', 'Temperature', 'Currency'];

export function Header({ converterType, setConverterType }: Props): JSX.Element {
  return (
    <>
      <header className={classes.header}>
        <div style={{ width: '20%' }}>
          <a href="/" className={classes.logo}>
            Simple Converter
          </a>
        </div>
        <div className={classes.buttons}>
          <ul style={{ padding: 0, margin: 0 }} className={classes.list}>
            {headerItems.map((el, idx) => (
              <li className={converterType === el.toLowerCase() ? `${classes.item} ${classes.selected}` : classes.item} onClick={() => setConverterType(el.toLowerCase())} key={idx}>
                <label style={{ cursor: 'pointer' }}>{el}</label>
              </li>
            ))}
          </ul>
        </div>
        <div style={{ width: '20%' }}></div>
      </header>
    </>
  );
}
