export default function temperatureConverter(value: number, input: string, output:string, ratio: {[key: string]: {[key: string]: number}}) {
    let res = 0;
    if(input === 'kel' && output === 'cel') {
        res = value - ratio.temperature.kel
    } else if (input === 'kel' && output === 'fah') {
        res = ((value - ratio.temperature.kel) * 1.8) + 32 // Kelvin to Fahrenheit conversion formula 
    } else if (input === 'cel' && output === 'kel') {
        res = value + ratio.temperature.kel
    } else if (input === 'cel' && output === 'fah') {
        res = (value * 1.8) + 32 // Celsius to Fahrenheit conversion formula 
    } else if (input === 'fah' && output === 'cel') {
        res = (value - 32) / 1.8 // Fahrenheit to Celsius conversion formula 
    } else if (input === 'fah' && output === 'kel') {
        res = ((value - 32) / 1.8) + ratio.temperature.kel // Fahrenheit to Kelvin conversion formula 
    } else res = value
    return res
}