import { useState, useEffect } from 'react';
import currencyAPI from '../services';
import ConverterForm from '../components/ConverterForm';
import { Header } from '../components/Header/Header';
import './App.scss';

function App() {
  const [converterType, setConverterType] = useState<string>('length');
  const [currenciesList, setCurrenciesList] = useState<any>();
  let ignore = false;

  useEffect(() => {
    if (!ignore) {
      currencyAPI.getCurrenciesList().then((res) => {
        setCurrenciesList(res.currencies);
      });
      ignore = true;
    }
  }, []);

  return (
    <div className="App">
      <Header converterType={converterType} setConverterType={setConverterType} />
      <ConverterForm converterType={converterType} currenciesList={currenciesList} />
    </div>
  );
}

export default App;
