const apiBase = 'http://api.currencylayer.com';

const {REACT_APP_API_KEY} = process.env;

const getCurrenciesList = async () => {
    try {
        return await fetch(`${apiBase}/list?access_key=${REACT_APP_API_KEY}`).then((res) => {return res.json()});
    } catch (err) {
        console.log(err)
    }
};
const getExchangeRates = async () => {
    try {
        return await fetch(`${apiBase}/live?access_key=${REACT_APP_API_KEY}`).then((res) => {return res.json()});
    } catch (err) {
        console.log(err)
    }
};

const currencyAPI = {
    getCurrenciesList,
    getExchangeRates
};

export default currencyAPI;