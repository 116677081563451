export function converterOptions(converterType: string) {
  if (converterType === 'length') {
    return (
      <>
        <option value="cm">Centimeter</option>
        <option value="dm">Decimeter</option>
        <option value="m">Meter</option>
        <option value="km">Kilometer</option>
        <option value="ft">Feet</option>
        <option value="mi">Mile</option>
      </>
    );
  } else if (converterType === 'weight') {
    return (
      <>
        <option value="mg">Miligram</option>
        <option value="g">Gram</option>
        <option value="kg">Kilogram</option>
        <option value="t">Ton</option>
      </>
    );
  } else if (converterType === 'speed') {
    return (
      <>
        <option value="mps">Metres per second</option>
        <option value="fps">Feet per second</option>
        <option value="kph">Kilometres per hour</option>
        <option value="miph">Miles per hour</option>
        <option value="knot">Knot</option>
      </>
    );
  } else if (converterType === 'temperature') {
    return (
      <>
        <option value="cel">Celsius </option>
        <option value="fah">Fahrenheit</option>
        <option value="kel">Kelvin</option>
      </>
    );
  }
}
