import temperatureConverter from "../temperatureConverter"
import currencyAPI from "../../services"

interface IConverter {
    inputValue: any,
    inputUnit: string,
    outputUnit: string,
    converterType: string
};

const ratio: {[key: string]: {[key: string]: number}} = {
    'length': {
        'cm': 100,
        'dm': 10,
        'm': 1,
        'km': 0.001,
        'ft': 3.2808,
        'mi': 0.0006213712,
    },
    'weight': {
        'mg': 1000,
        'g': 1,
        'kg': 0.001,
        't': 0.000001,
    },
    'speed': {
        'mps': 1,
        'fps': 3.28,
        'kph': 3.6,
        'miph': 2.2369,
        'knot': 1.9438
    },
    'temperature': {
        'cel': 1,
        'fah': 33.8,
        'kel': 273.15,
    }
}
setTimeout(() => {
    currencyAPI.getExchangeRates().then((res) => {
        let currency: {[key: string]: number} = {}
        for(let key in res.quotes) {
            currency[key.slice(3, 7)] = res.quotes[key]
        }
        ratio['currency'] = currency
        ratio['currency']['USD'] = 1
    })
}, 2000)

export function converter ({inputValue, inputUnit, outputUnit, converterType}: IConverter) {
    if(converterType === 'temperature') {
        const res = temperatureConverter(Number(inputValue), inputUnit, outputUnit, ratio)
        return Number(res.toFixed(10));
    }
    const res: number = inputValue / ratio[converterType][inputUnit] * ratio[converterType][outputUnit];
    return Number(res.toFixed(10));
}